/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, {
    useState, useEffect, useCallback, useContext
} from 'react';
import { validateMetadata, renderContentFromMetadata } from '@jutro/uiconfig';
// importing TranslatorContext and LocaleService
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { useHistory } from 'react-router-dom';
import { Link } from '@jutro/components';
import message from './header.messages';
import uiMetadata from './header.metadata.json5';
import styles from './header.module.scss';
// importing UtilService
import UtilService from '../../services/UtilService';
import SendMessage from '../../claim-details/messaging/send-message/send-message.messages';
import ReplyMessage from '../../claim-details/messaging/reply-message/reply-message.messages';
// import metadata for LeftNavigation.
import metadata from '../left-nav/left-nav.metadata.json5';
import OSBCClaimService from '../../services/OSBCClaimService';
import showIneligibleClaimModal from '../Ineligible-claim/Ineligible-claim';
import { useAuthentication } from 'gw-digital-auth-react';
import AccountSendMessage from '../../../account/messaging/send-message/account-send-message.messages';
import EDUAccountService from '../../../account/services/EDUAccountService';

export default function Header() {
    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [userName, setUserName] = useState('');
    const [iAmType, setIAmType] = useState('Business');
    const [showModal, setVisiblePropertyForModal] = useState(false);
    const [activeLocale, setActiveLocale] = useState(
        LocaleService.getStoredLocale()
    );
    const [activeLanguage, setActiveLanguage] = useState(
        LocaleService.getStoredLanguage()
    );
    const [isUserLoggedIn, setUserLoogedIn] = useState(false);
    const [showLandgingLink, setshowLandgingLink] = useState(false);

    const [showLeftNav, setshowLeftNav] = useState(false);
    const [accountLeftNav, setAccountLeftNav] = useState(false);
    const [eformLeftNav, setEformLeftNav] = useState(false);

    const { authHeader } = useAuthentication();

    const updateActiveLocale = useCallback((locale) => {
        UtilService.languageChanged = true;
        LocaleService.setCurrentLocale(locale);
        setActiveLocale(locale);
    }, []);

    const updateActiveLanguage = useCallback((language) => {
        UtilService.languageChanged = true;
        LocaleService.setCurrentLanguage(language);
        setActiveLanguage(language);
    }, []);

    const [count, setCount] = useState('');
    const [accountMsgCount, setAccountMsgCount] = useState('');
    const history = useHistory();

    /**
    * getting message count method
    */
    const messageCount = useCallback(() => {
        const claimNumber = UtilService.fetchClaimNumber();
        if (claimNumber.length === 8) {
            const serviceName = UtilService.getServiceName('customerMessage');
            const mailList = OSBCClaimService.messageCount(claimNumber, serviceName, '');
            mailList.then((result) => {
                if (result === 0) {
                    setCount('');
                } else {
                    setCount(`(${result})`);
                }
            }, (error) => {
                // eslint-disable-next-line no-console
                if (error.baseError === '840' || error.baseError === '844' || error.baseError === '842') {
                    showIneligibleClaimModal(error.baseError);
                }
                setCount('');
            });
        }
    }, []);

    /**
    * getting message count method for account
    */
    const accountMessageCount = useCallback(() => {
        const accountNumber = UtilService.fetchAccountNumber();
        if (accountNumber.length === 7) {
            const serviceName = UtilService.getServiceName('accountMessageCount');
            const mailList = EDUAccountService.accountMessageCount(accountNumber, serviceName, '');
            mailList.then((result) => {
                if (result === 0) {
                    setAccountMsgCount('');
                } else {
                    setAccountMsgCount(`(${result})`);
                }
            }, (error) => {
                // eslint-disable-next-line no-console
                if (error.baseError === '840' || error.baseError === '844' || error.baseError === '842') {
                    showIneligibleClaimModal(error.baseError);
                }
                setAccountMsgCount('');
            });
        }
    }, []);

    /**
       * redirect to claim overview screen
       */
    const redirectToRoute = useCallback(
        (value) => {
            messageCount();
            accountMessageCount();
            if (window.location.pathname.indexOf('sendmessage') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('documentUpload') !== -1) {
                if (sessionStorage.getItem('documentAdded') === '1') {
                    const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                        document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
                if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                    const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                    allowNavigate.then((status) => {
                        if (status) {
                            history.push(`/${value}`);
                        }
                    }, () => {
                    });
                } else {
                    history.push(`/${value}`);
                }
            } else {
                history.push(`/${value}`);
            }
        },
        [history, messageCount, accountMessageCount, translator]
    );

    /*
     * to fetch loggedin user name, Integration service will be called?
     */
    const fetchUserName = () => {
        if (auth.isLoggedIn) {
            setUserName(auth.name);
            setUserLoogedIn(true);
        } else {
            setUserLoogedIn(false);
        }
    };

    /*
     * to check route path, visivility of sidenav/Account Side Nav will be handled
     */
    const displaySidenav = () => {
        const pageName = window?.location?.pathname?.split('/')[2];
        if (['account-document-upload', 'account-send-message', 'account-mail', 'account-mailDetail', 'account-helpful-link', 'account-faq'].indexOf(pageName) >= 0) {
            setshowLeftNav(false);
            setAccountLeftNav(true);
        } else if (['view-claim-list-home', 'claimOverview', 'benefitPayment', 'healthCare', 'appeals', 'documentUpload', 'sendmessage', 'mail', 'helpful-link',
            'faq'].indexOf(pageName) >= 0) {
            setAccountLeftNav(false);
            setshowLeftNav(true);
        } else if (['view-reports-home-page', 'illness-injury-home-page', 'exposure-home-page', 'fatality-home-page', 'account-helpful-link', 'account-faq'].indexOf(pageName) >= 0) {
            setEformLeftNav(true);
            setshowLeftNav(false);
            setAccountLeftNav(false);
        } else {
            setshowLeftNav(false);
            setAccountLeftNav(false);
            setEformLeftNav(false);
        }
    };


    /*
     * to check route path, visivility of Unified Landing Page on OSBC pages will be handled
     */
    const displayLandingLink = () => {
        const pageName = window?.location?.pathname?.split('/')[2];
        if (['claims', 'session-expired', 'view-claim-list-home', 'claimOverview', 'benefitPayment',
            'healthCare', 'appeals', 'documentUpload', 'sendmessage', 'mail', 'mailDetail',
            'helpful-link', 'faq'].indexOf(pageName) !== -1) {
            setshowLandgingLink(true);
        } else {
            setshowLandgingLink(false);
        }
    };

    /*
     * to call global logout and redirect the user to LFB login screen.
     */
    const logout = useCallback(() => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    // applying google analytics tag
                    UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1 || window.location.pathname.indexOf('account-document-upload') !== -1 || window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        if (window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPageUnNum', LocaleService.getStoredLanguage());
                        } else if (window.location.pathname.indexOf('account-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPage', LocaleService.getStoredLanguage());
                        }
                        auth.logout().then(() => {
                            // applying google analytics tag
                            UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                });
            } else {
                auth.logout().then(() => {
                    // applying google analytics tag
                    UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            // applying google analytics tag
                            UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    // applying google analytics tag
                    UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            UtilService.publishGA('Bounce', 'DUE_SendMessage_Bounce', LocaleService.getStoredLanguage());
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    UtilService.publishGA('Bounce', 'DUE_SendMessage_Bounce', LocaleService.getStoredLanguage());
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        auth.logout().then(() => {
                            sessionStorage.clear();
                            UtilService.deleteAllCookies();
                            window.location.href = UtilService.unifiedLogout(activeLanguage);
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                auth.logout().then(() => {
                    // applying google analytics tag
                    UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                    sessionStorage.clear();
                    UtilService.deleteAllCookies();
                    window.location.href = UtilService.unifiedLogout(activeLanguage);
                });
            }
        } else {
            auth.logout().then(() => {
                // applying google analytics tag
                UtilService.publishGA('Exit Page View', 'ExitPage_Abandonment', LocaleService.getStoredLanguage());
                sessionStorage.clear();
                UtilService.deleteAllCookies();
                window.location.href = UtilService.unifiedLogout(activeLanguage);
            });
        }
    }, [auth, activeLanguage]);

    /*
     *  get business link
     */
    const getBusinessLink = useCallback(
        () => {
            return UtilService.businessLink(activeLanguage);
        },
        [activeLanguage]
    );

    /*
     *  get profile link
     */
    const getProfileLink = useCallback(
        () => {
            return UtilService.profileLink(activeLanguage);
        },
        [activeLanguage]
    );


    /*
     * navigate to Ill or Injured and Business.
     */
    const onChangeOfIamDropdown = useCallback((event) => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    break;
                                case 'Ill or Injured':
                                    window.location.href = UtilService.illOrInjured(activeLanguage);
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            break;
                        case 'Ill or Injured':
                            window.location.href = UtilService.illOrInjured(activeLanguage);
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1 || window.location.pathname.indexOf('account-document-upload') !== -1 || window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        if (window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPageUnNum', LocaleService.getStoredLanguage());
                        } else if (window.location.pathname.indexOf('account-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPage', LocaleService.getStoredLanguage());
                        }
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    break;
                                case 'Ill or Injured':
                                    window.location.href = UtilService.illOrInjured(activeLanguage);
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            break;
                        case 'Ill or Injured':
                            window.location.href = UtilService.illOrInjured(activeLanguage);
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    break;
                                case 'Ill or Injured':
                                    window.location.href = UtilService.illOrInjured(activeLanguage);
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            break;
                        case 'Ill or Injured':
                            window.location.href = UtilService.illOrInjured(activeLanguage);
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    break;
                                case 'Ill or Injured':
                                    window.location.href = UtilService.illOrInjured(activeLanguage);
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            break;
                        case 'Ill or Injured':
                            window.location.href = UtilService.illOrInjured(activeLanguage);
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        setIAmType(() => {
                            switch (event) {
                                case 'Business':
                                    break;
                                case 'Ill or Injured':
                                    window.location.href = UtilService.illOrInjured(activeLanguage);
                                    break;
                                default:
                                    break;
                            }
                            return event;
                        });
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                setIAmType(() => {
                    switch (event) {
                        case 'Business':
                            break;
                        case 'Ill or Injured':
                            window.location.href = UtilService.illOrInjured(activeLanguage);
                            break;
                        default:
                            break;
                    }
                    return event;
                });
            }
        } else {
            setIAmType(() => {
                switch (event) {
                    case 'Business':
                        break;
                    case 'Ill or Injured':
                        window.location.href = UtilService.illOrInjured(activeLanguage);
                        break;
                    default:
                        break;
                }
                return event;
            });
        }
    }, [activeLanguage]);

    /*
     * navigate to Ill or Injured and Business.
     */
    const navigateToMyProfile = useCallback((event) => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getProfileLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getProfileLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1 || window.location.pathname.indexOf('account-document-upload') !== -1 || window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        if (window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPageUnNum', LocaleService.getStoredLanguage());
                        } else if (window.location.pathname.indexOf('account-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPage', LocaleService.getStoredLanguage());
                        }
                        window.location.href = getProfileLink('pageMyProfile');
                    }
                }, () => {
                });
            } else {
                window.location.href = getProfileLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getProfileLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getProfileLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getProfileLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getProfileLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getProfileLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getProfileLink('pageMyProfile');
            }
        } else {
            window.location.href = getProfileLink('pageMyProfile');
        }
    }, [activeLanguage]);

    /*
     * navigate to Landing Screen.
     */
    const navigateToLandingScreen = useCallback(() => {
        const path = '/unified-landing-screen';
        history.push(path);
    }, []);

    /*
     * navigate to Ill or Injured and Business.
     */
    const navigateToBusiness = useCallback((event) => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getBusinessLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getBusinessLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1 || window.location.pathname.indexOf('account-document-upload') !== -1 || window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG22), translator(SendMessage.StayOnPage), translator(SendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        if (window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPageUnNum', LocaleService.getStoredLanguage());
                        } else if (window.location.pathname.indexOf('account-document-upload') !== -1) {
                            UtilService.publishGA('Warning', 'DUE_Warning_ExitedPage', LocaleService.getStoredLanguage());
                        }
                        sessionStorage.removeItem('isHome');
                        window.location.href = getBusinessLink('pageMyProfile');
                    }
                }, () => {
                });
            } else {
                window.location.href = getBusinessLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG22), translator(ReplyMessage.StayOnPage), translator(ReplyMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getBusinessLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getBusinessLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getBusinessLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getBusinessLink('pageMyProfile');
            }
        } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG22), translator(AccountSendMessage.StayOnPage), translator(AccountSendMessage.LeavePage));
                allowNavigate.then((status) => {
                    if (status) {
                        window.location.href = getBusinessLink('pageMyProfile');
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                window.location.href = getBusinessLink('pageMyProfile');
            }
        } else {
            window.location.href = getBusinessLink('pageMyProfile');
        }
    }, [activeLanguage]);


    /*
     * to display responsive menu
     */
    const menuIconClicked = useCallback(() => {
        document.body.classList.add('noScroll');
        setVisiblePropertyForModal(() => {
            return true;
        });
    }, []);

    /*
     *  to close modal
     */
    const closeModal = useCallback(() => {
        document.body.classList.remove('noScroll');
        setVisiblePropertyForModal(() => {
            return false;
        });
    }, []);

    /*
     * @param {string} key
     * @returns content
     * get content on the basis of key
     */
    const fetchContent = useCallback(
        (key) => {
            return translator(message[key]);
        },
        [translator]
    );
    // eslint-disable-next-line no-trailing-spaces

    /*
     *  change language method
     */
    const changeLanguage = useCallback(() => {
        if (window.location.pathname.indexOf('sendmessage') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG79), translator(SendMessage.Cancel), translator(SendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        // console.log(1);
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                // console.log(2);
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('documentUpload') !== -1 || window.location.pathname.indexOf('account-document-upload') !== -1 || window.location.pathname.indexOf('unnumbered-document-upload') !== -1) {
            if (sessionStorage.getItem('documentAdded') === '1') {
                const allowNavigate = UtilService.navigateAway(translator(SendMessage.MSG79), translator(SendMessage.Cancel), translator(SendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        UtilService.publishGA('Warning', 'DUE_SystemWarning_ChangeLanguageUploading', LocaleService.getStoredLanguage());
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        // console.log(3);
                        window.location.reload();
                    }
                }, () => {
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                // console.log(4);
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(ReplyMessage.MSG79), translator(ReplyMessage.Cancel), translator(ReplyMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        // console.log(5);
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                // console.log(6);
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('account-send-message') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG79), translator(AccountSendMessage.Cancel), translator(AccountSendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        UtilService.publishGA('Warning', 'DUE_SystemWarning_ChangeLanguage', LocaleService.getStoredLanguage());
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                // console.log(2);
                window.location.reload();
            }
        } else if (window.location.pathname.indexOf('account-mailDetail') !== -1) {
            if (document.getElementById('mainBody').getAttribute('sendMessageDirty')) {
                const allowNavigate = UtilService.navigateAway(translator(AccountSendMessage.MSG79), translator(AccountSendMessage.Cancel), translator(AccountSendMessage.Okay));
                allowNavigate.then((status) => {
                    if (status) {
                        UtilService.publishGA('Warning', 'DUE_SystemWarning_ChangeLanguage', LocaleService.getStoredLanguage());
                        if (activeLanguage === 'fr') {
                            updateActiveLanguage('en');
                            updateActiveLocale('en');
                        } else {
                            updateActiveLanguage('fr');
                            updateActiveLocale('fr');
                        }
                        // console.log(5);
                        window.location.reload();
                    }
                }, () => {
                    document.getElementById('mainBody').setAttribute('sendMessageDirty', true);
                });
            } else {
                if (activeLanguage === 'fr') {
                    updateActiveLanguage('en');
                    updateActiveLocale('en');
                } else {
                    updateActiveLanguage('fr');
                    updateActiveLocale('fr');
                }
                // console.log(6);
                window.location.reload();
            }
        } else {
            /* eslint no-lonely-if: "error" */
            /* eslint prefer-template: "error" */
            /* eslint-env es6 */
            // eslint-disable-next-line no-lonely-if
            if (activeLanguage === 'fr') {
                updateActiveLanguage('en');
                updateActiveLocale('en');
                window.location = window.location.href.replace('=fr', '=en');
            } else {
                updateActiveLanguage('fr');
                updateActiveLocale('fr');
                window.location = window.location.href.replace('=en', '=fr');
            }
            // console.log(activeLanguage);
            // window.location.reload();
        }
    }, [activeLanguage, UtilService.navigateAway, updateActiveLanguage, updateActiveLocale]);

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const getLink = useCallback(
        (key) => {
            const href = UtilService.onlineSerices(key, LocaleService.getCurrentLanguage());
            return (
                <Link href={href} className={styles.headerHyperLinks}>
                    {translator(message[key])}
                </Link>
            );
        },
        [translator]
    );

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const navigateLink = useCallback(
        (key) => {
            const claimNumber = UtilService.fetchClaimNumber();
            const accountNumber = UtilService.fetchAccountNumber();
            switch (key) {
                case 'claimOverview':
                    return (
                        <Link onClick={() => { redirectToRoute(`claimOverview/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'benefitPayment':
                    return (
                        <Link onClick={() => { redirectToRoute(`benefitPayment/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'healthCareBenefits':
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link onClick={() => { redirectToRoute(`healthCare/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'appeals':
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link onClick={() => { redirectToRoute(`appeals/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'claimDocuments':
                    return (
                        <Link onClick={() => { redirectToRoute(`documentUpload/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'sendUsMessage':
                    return (
                        <Link onClick={() => { redirectToRoute(`sendmessage/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'mail':
                    return (
                        <Link onClick={() => { redirectToRoute(`mail/${claimNumber}`); }}>
                            {`${translator(message[key])}${count}`}
                        </Link>
                    );
                case 'helpfulLink':
                    return (
                        <Link onClick={() => { redirectToRoute(`helpful-link/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'faq':
                    return (
                        <Link onClick={() => { redirectToRoute(`faq/${claimNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'accountMailDetails':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-mailDetail/${accountNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                default: return (
                    <Link onClick={() => { redirectToRoute(`claims/${claimNumber}`); }}>
                        {translator(message[key])}
                    </Link>
                );
            }
        },
        [count, accountMsgCount, redirectToRoute, translator]
    );

    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const navigateLinkAccount = useCallback(
        (key) => {
            const accountNumber = UtilService.fetchAccountNumber();
            switch (key) {
                case 'accountDocuments':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-document-upload/${accountNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'sendUsMessageForAccount':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-send-message/${accountNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'accountMailHome':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-mail/${accountNumber}`); }}>
                            {`${translator(message[key])}${accountMsgCount}`}
                        </Link>
                    );
                case 'accountHelpfulLinks':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-helpful-link/${accountNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'accountFaq':
                    return (
                        <Link onClick={() => { redirectToRoute(`account-faq/${accountNumber}`); }}>
                            {translator(message[key])}
                        </Link>
                    );
                default: return (
                    <Link onClick={() => { redirectToRoute(`account-document-upload/${accountNumber}`); }}>
                        {translator(message[key])}
                    </Link>
                );
            }
        },
        [accountMsgCount, redirectToRoute, translator]
    );


    useEffect(() => {
        validateMetadata(uiMetadata);
        fetchUserName();
        displaySidenav();
        displayLandingLink();
    });

    // AODA Skip to main content function
    const skipLink = (e) => {
        const nodes = document.querySelectorAll('.osbcMainContainer');
        const noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
        if (!e.key || e.key === 'Enter') {
            if (nodes.length > 0) {
                if (nodes[0].id) {
                    document.location.hash = nodes[0].id;
                }
            }
        }
        return false;
    };

    const navigateLinkEform = useCallback(
        (key) => {
            // const accountNumber = UtilService.fetchAccountNumber();
            switch (key) {
                case 'submitNewReport':
                    return (
                        <Link onClick={() => { redirectToRoute('view-reports-home-page'); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'Illness':
                    return (
                        <Link onClick={() => { redirectToRoute('illness-injury-home-page'); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'Exposure':
                    return (
                        <Link onClick={() => { redirectToRoute('exposure-home-page'); }}>
                            {`${translator(message[key])}${accountMsgCount}`}
                        </Link>
                    );
                case 'Fatality':
                    return (
                        <Link onClick={() => { redirectToRoute('fatality-home-page'); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'FAQ':
                    return (
                        <Link onClick={() => { redirectToRoute('account-faq'); }}>
                            {translator(message[key])}
                        </Link>
                    );
                case 'HelpfulLink':
                    return (
                        <Link onClick={() => { redirectToRoute('account-helpful-link'); }}>
                            {translator(message[key])}
                        </Link>
                    );
                default: return (
                    <Link onClick={() => { redirectToRoute('view-reports-home-page'); }}>
                        {translator(message[key])}
                    </Link>
                );
            }
        },
        [redirectToRoute, translator]
    );


    /*
     *  component level overrids
     */
    const override = {
        iAmDropdown: {
            visible: isUserLoggedIn,
            value: iAmType,
        },
        appComponentSkipToMainContent: {
            content: 'Skip to main content'
        },
        iAmDropdownLabel: {
            visible: isUserLoggedIn,
            content: fetchContent('iAm')
        },
        mobileiAmDropdownLabel: {
            visible: isUserLoggedIn,
            content: fetchContent('iAm')
        },
        responsiveiAmDropdown: {
            value: iAmType
        },
        username: {
            visible: isUserLoggedIn,
            content: `${fetchContent('username')} ${userName}`
        },
        mobileusername: {
            visible: isUserLoggedIn,
            content: `${fetchContent('username')} ${userName}`
        },
        modal: {
            visible: showModal
        },
        backdrop: {
            visible: showModal
        },
        navlinkBusiness: {
            content: fetchContent('businessHome'),
            visible: isUserLoggedIn
        },
        navlinkLandingScreen: {
            content: fetchContent('unifiedLandingScreen'),
            visible: isUserLoggedIn && showLandgingLink
        },
        navlinkProfile: {
            content: fetchContent('myProfile'),
            visible: isUserLoggedIn
        },
        navlinkLogout: {
            content: fetchContent('logout'),
            visible: isUserLoggedIn
        },
        mobilenavlinkBusiness: {
            content: fetchContent('businessHome'),
            visible: isUserLoggedIn
        },
        mobilenavlinkLandingScreen: {
            content: fetchContent('unifiedLandingScreen'),
            visible: isUserLoggedIn && showLandgingLink
        },
        mobilenavlinkProfile: {
            content: fetchContent('myProfile'),
            visible: isUserLoggedIn
        },
        mobilenavlinkLogout: {
            content: fetchContent('logout'),
            visible: isUserLoggedIn
        },
        navlinkTranslations: {
            content: fetchContent('french'),
            rel: activeLanguage === 'en' ? 'fr' : 'en',
            lang: activeLanguage === 'en' ? 'fr' : 'en'
        },
        mobileNavlinkTranslations: {
            content: fetchContent('french')
        },
        menu: {
            content: fetchContent('menu')
        },
        mobiledocumentsLabel: {
            content: fetchContent('documents')
        },
        mobilemessagesLabel: {
            content: fetchContent('messages')
        },
        mobileresourcesLabel: {
            content: fetchContent('resources')
        },

        mobileclaimInformationLabel: {
            content: fetchContent('claimInformation')
        },
        mobileclaimOverviewLink: {
            content: navigateLink('claimOverview')
        },
        mobilebenefitPaymentLink: {
            content: navigateLink('benefitPayment')
        },
        mobilehealthCareBenefitslink: {
            content: navigateLink('healthCareBenefits')
        },
        mobileappealslink: {
            content: navigateLink('appeals')
        },
        mobileclaimDocumentsLink: {
            content: navigateLink('claimDocuments')
        },
        mobilesendUsMessageLink: {
            content: navigateLink('sendUsMessage')
        },
        mobilemailLink: {
            content: navigateLink('mail')
        },
        mobileHelpfulLink: {
            content: navigateLink('helpfulLink')
        },
        mobilefaqLink: {
            content: navigateLink('faq')
        },
        mobileAccountDocumentsLink: {
            content: navigateLinkAccount('accountDocuments')
        },
        mobileAccountMailLink: {
            content: navigateLinkAccount('accountMailHome')
        },
        mobileAccountSendUsMessageLink: {
            content: navigateLinkAccount('sendUsMessageForAccount')
        },
        mobileAccountFaqLink: {
            content: navigateLinkAccount('accountFaq')
        },
        mobileAccountHelpfulLink: {
            content: navigateLinkAccount('accountHelpfulLinks')
        },
        onlineServiceLabel: {
            content: fetchContent('onlineServices')
        },
        navlinkExit: {
            content: getLink('exit'),
            visible: !isUserLoggedIn
        },
        mobilenavlinkExit: {
            content: getLink('exit'),
            visible: !isUserLoggedIn
        },
        iMADropsoenContentContainer: {
            visible: isUserLoggedIn
        },
        responsiveSidenav: {
            visible: isUserLoggedIn && showLeftNav
        },
        responsiveSidenavAccount: {
            visible: isUserLoggedIn && accountLeftNav
        },
        logo: {
            href: LocaleService.getStoredLanguage() === 'fr' ? 'https://www.wsib.ca/fr' : 'https://www.wsib.ca/'
        },
        responsiveSidenavEform: {
            visible: eformLeftNav
        },
        mobileReportsLabel: {
            content: fetchContent('reports')
        },
        mobileSubmitNewReportLink: {
            content: navigateLinkEform('submitNewReport')
        },
        mobileIllnessLink: {
            content: navigateLinkEform('Illness')
        },
        mobileExposureLink: {
            content: navigateLinkEform('Exposure')
        },
        mobileFatalityLink: {
            content: navigateLinkEform('Fatality')
        },
        mobileresourceLabel: {
            content: fetchContent('ReportsResources')
        },
        mobileFAQLink: {
            content: navigateLinkEform('reportsFAQ')
        },
        mobileHelpfullLink: {
            content: navigateLinkEform('reportsHelpfulLinks')
        }
    };

    /*
     *  component level resolvers
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            logout,
            onChangeOfIamDropdown,
            menuIconClicked,
            closeModal,
            changeLanguage,
            navigateToMyProfile,
            navigateToBusiness,
            navigateToLandingScreen,
            skipLink
        }
    };

    return renderContentFromMetadata(
        uiMetadata.osbcHeaderComponent,
        override,
        resolvers
    );
}
